'use client'

import useLogin from '@hook/useLogin'
import useMessage from '@hook/useMessage'
import { googleLogin } from '@lib/fetch/login'
import { LoadingButton } from '@mui/lab'
import clsx from 'clsx'
import { assign, isFunction } from 'lodash-es'
import Script from 'next/script'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { useAsyncFn, useBoolean, useWindowSize } from 'react-use'

function useGoogleButton() {
  const { message } = useMessage()
  const { setUser, closeDialog } = useLogin()

  const [googleLoginState, handleGoogleLogin] = useAsyncFn(async (data) => {
    const res = await googleLogin(data.credential).catch((err) => {
      message(err.message)
      return false
    })
    if (res) {
      setUser(res)
      closeDialog()
    }
  })

  return {
    googleLoginState,
    handleGoogleLogin
  }
}

export default function GoogleButton({
  small = false
}) {
  const selfRef = useRef(null)
  const { googleLoginState, handleGoogleLogin } = useGoogleButton()
  return (
    <GoogleButtonLayout
      small={small}
      ref={selfRef}
      onSuccess={handleGoogleLogin}
    >
      <LoadingButton
        className={clsx('p-0 min-w-0', { '!flex w-full': !small })}
        loading={googleLoginState.loading}
        sx={() => ({ '& .MuiLoadingButton-loadingIndicator': { zIndex: 1 } })}
      >
        <div ref={selfRef} className={clsx({ 'w-full': !small })} />
      </LoadingButton>
    </GoogleButtonLayout>
  )
}

function GoogleButtonLayoutComp({
  children,
  small = false,
  onSuccess,
  onClick,
  ops
}, ref) {
  const { user } = useLogin()
  const [loaded, toggleLoaded] = useBoolean(false)

  const initialize = useCallback(() => {
    if (typeof google === 'undefined' || user) return
    if (loaded) {
      isFunction(onSuccess) && onSuccess()
      return
    }

    window.google.accounts.id.initialize({
      // prompt_parent_id: 'google-onetap-element',
      cancel_on_tap_outside: false,
      client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENTID,
      callback: onSuccess
    })
    toggleLoaded(true)
  }, [onSuccess, loaded, toggleLoaded, user])

  const [prevWidth, setPreWidth] = useState(-1)
  const renderButton = useCallback(() => {
    if (user) return

    const { current } = ref
    const containerWidth = current.offsetWidth
    if (!loaded || !current || prevWidth === containerWidth) return
    const config = small ? {
      size: 'medium',
      type: 'icon',
      shape: 'circle'
    } : {
      size: 'large',
      type: 'standard',
      theme: 'outline',
      text: 'continue_with',
      logo_alignment: 'center',
      width: containerWidth
    }

    setPreWidth(containerWidth)

    window.google.accounts.id.renderButton(current, assign(config, { click_listener: onClick, locale: 'en' }, ops))
    window.google.accounts.id.prompt()
  }, [loaded, ref, small, onClick, ops, prevWidth, setPreWidth, user])

  // cancel
  const cancelHandle = useCallback(() => {
    if (!loaded) return

    window.google.accounts.id.cancel()
  }, [loaded])

  const { width, height } = useWindowSize()
  useEffect(() => {
    renderButton()
  }, [renderButton, width, height])

  // 如果用户已经登录，取消按钮
  useEffect(() => {
    if (user) {
      cancelHandle()
    }
  }, [user, cancelHandle])
  if (user) return null

  return (
    <>
      {children}
      <Script src="https://accounts.google.com/gsi/client" strategy="lazyOnload" onReady={initialize} />
    </>
  )
}
export const GoogleButtonLayout = forwardRef(GoogleButtonLayoutComp)
