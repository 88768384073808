/**
 * 用户中心入口
 * 
 * @Author: Focci
 * @Date: 2023-05-23 10:58:06
 * @Last Modified by: Focci
 * @Last Modified time: 2023-05-23 10:58:06
 */

'use client'

// import PropTypes from 'prop-types'
import HoverDropdown from '@comp/HoverDropdown'
import { Avatar, CircularProgress } from '@comp/material'
import useLazyComponent from '@hook/useLazyComponent'
import useLogin from '@hook/useLogin'
import { editProfile } from '@lib/route/profile'
import clsx from 'clsx'
import { useLocale, useTranslations } from 'next-intl'
import { useCallback, useMemo } from 'react'
import GoogleButton from '../login/button/google'

function AuthButton({
  children,
  className = 'bg-white hover:bg-white/80',
  onClick = () => { }
}) {
  return (
    <div
      onMouseDown={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
      className={clsx([
        'rounded-[.2rem] h-6 px-3 cursor-pointer',
        'flex items-center select-none',
        className
      ])}
    >
      {children}
    </div>
  )
}

export default function Auth() {
  const locale = useLocale()
  const tl = useTranslations('login')
  const { Wedget: ProfilePanel, getWedget, ready } = useLazyComponent()
  const { openSigninDialog, openSignupDialog, isLogin, user } = useLogin()
  const avatarSize = useMemo(() => ({ width: '1.825rem', height: '1.825rem' }), [])

  const handleEnter = useCallback(() => {
    getWedget(() => import('./profile-panel'))
  }, [getWedget])

  if (!isLogin) {
    return (
      <div className="flex items-center gap-x-1">
        {
          !user && (
            <div className="hidden">
              <GoogleButton />
            </div>
          )
        }
        <AuthButton
          onClick={openSigninDialog}
          className="text-white hover:bg-white/10"
        >
          {tl('login')}
        </AuthButton>
        <AuthButton onClick={openSignupDialog}>
          {tl('register')}
        </AuthButton>
      </div>
    )
  }

  return (
    <HoverDropdown
      trigger={<Avatar src={user?.avatar} sx={avatarSize} alt={user?.nickname} />}
      onEnter={handleEnter}
      tag="a"
      tagProps={{
        href: editProfile(locale),
        className: `
          inline-flex rounded-full cursor-pointer border border-2 
          border-transparent group/hover-dropdown-wrapper:border-white/50
        `
      }}
    >
      <div className="bg-white shadow-large rounded-md">
        {!ready && (
          <div className="w-72 py-8 text-center">
            <CircularProgress />
          </div>
        )}
        <ProfilePanel user={user} />
      </div>
    </HoverDropdown>
  )
}

// Auth.propTypes = {
//   userToken: PropTypes.string,
// }
