/**
 * 延迟加载组件
 * 
 * @Author: Focci
 * @Date: 2023-12-02 10:51:41
 * @Last Modified by: Focci
 * @Last Modified time: 2023-12-02 10:51:41
 */

import { useCallback, useRef, useState } from 'react'

export default function useLazyComponent() {
  const estimateBody = useRef(null)
  const [ready, setReady] = useState(false)
  
  const getWedget = useCallback(async (callback, prop = 'default') => {
    if(!estimateBody.current) {
      estimateBody.current = (await callback())[prop]
    }
    setReady(true)
  }, [])

  const Wedget = useCallback((props) => {
    const Component = estimateBody.current
    if(!Component || !ready) {
      return null
    }

    return <Component {...props} />
  }, [ready])

  return {
    ready,
    getWedget,
    Wedget,
  }
}
