/**
 * Custom Dialog
 * 
 * @Author: Focci
 * @Date: 2023-07-24 15:32:17
 * @Last Modified by: Focci
 * @Last Modified time: 2023-07-24 15:32:17
 */

'use client'

import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import { isFunction } from 'lodash-es'
import {
  Dialog, IconButton, DialogTitle, DialogContent, DialogActions
} from '@comp/material'

export default function CustomDialog({
  title = null,
  subtile = true, // 是否有背景颜色 #f8f8f8
  width = '50rem', // 宽度
  headWidth = '50rem', // 宽度
  children,
  onClose = null,
  DialogTitleProps = null,
  DialogContentProps = null,
  action = null,
  DialogActionProps = null,
  ...DialogProps
}) {
  return (
    <Dialog
      scroll="body" 
      {...DialogProps}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: subtile ? '#f8f8f8' : '#fff'
        },
      }}
    >
      {
        (title || onClose) && (
          <DialogTitle
            className="
              flex items-center justify-between max-w-full mx-auto 
              sticky top-0 !p-1 md:!p-4
            "
            style={{ width: headWidth }}
            {...DialogTitleProps}
          >
            <div className="text-base pr-12">
              {title}
            </div>
            {isFunction(onClose) ? (
              <IconButton onClick={onClose} className="-mr-1">
                <CloseIcon className="text-f1.2 text-primary" />
              </IconButton>
            ) : null}
          </DialogTitle>
        )
      }
      <DialogContent
        className="max-w-full mx-auto !p-2 md:!px-4" 
        style={{ width }} 
        {...DialogContentProps}
      >
        {children}
      </DialogContent>
      {action && (
        <DialogActions
          className="max-w-full mx-auto"  
          style={{ width }} 
          {...DialogActionProps}
        >
          {action}
        </DialogActions>
      )}
    </Dialog>
  )
}

CustomDialog.propTypes = {
  title: PropTypes.node,
  subtile: PropTypes.bool,
  width: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  DialogTitleProps: PropTypes.object,
  DialogContentProps: PropTypes.object,
  action: PropTypes.node,
  DialogActionProps: PropTypes.object,
}

export function CustomDialogWithTitle({
  title,
  contentClassName = 'bg-white p-4',
  children,
  ...dialogProps
}) {
  return (
    <CustomDialog 
      fullScreen
      width="45rem"
      // open={open}
      // onClose={() => setOpen(false)}
      {...dialogProps}
    >
      <div className={contentClassName}>
        {title && (
          <div className="text-center text-f1.2 font-medium my-6">
            {title}
          </div>
        )}
        {dialogProps?.open && children}
      </div>
    </CustomDialog>
  )
}
